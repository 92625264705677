.privacy-container{
 text-align: start;
}
.privacy-main{
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    box-shadow: 0 0 5px gray;
    padding: 20px;
    margin-top: 10vh;
    margin-bottom: 10vh;
}
.privacy-main {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .privacy-main h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .privacy-main p {
    margin-bottom: 15px;
  }
  
  .privacy-main ul {
    list-style-type: disc; /* Bullet points */
    margin-left: 20px; /* Indentation */
  }
  
  .privacy-main li {
    margin-bottom: 5px;
  }