
  /*flight availability css*/
  .hotelcontainer { 
 
     background: transparent;
      }
      
      .dummy2{
        background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9);
        height: 8vh;
        width: 100%;
      }
      .hotelcontainerrow{
        display: flex;
        flex-wrap: wrap;
        /* background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9); */
        background-color: rgb(237, 238, 243);
        margin: 50px;
      }
.hotel-second-containerrow{
  display: flex;
        flex-wrap: wrap;
        /* background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9); */
        background-color: rgb(237, 238, 243);
       
}



      /* resultpage style for hotel */

   .result-card{
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 20px;
   }
   .resultpage-col{
    width: 700px;
    padding: 10px;
    border: 1px solid blue;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr repeat(2, 1fr);
   }
   .card-row1 { grid-area: 1 / 1 / 2 / 2; }
   .card-row2 { grid-area: 2 / 1 / 3 / 2; 
  display: flex;
  flex-wrap: wrap;
  }
.card-row3 { grid-area: 3 / 1 / 4 / 2;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-rows: 1fr;
}
.card-row3-col1 { grid-area: 1 / 1 / 2 / 2; }
.card-row3-col2 { grid-area: 1 / 2 / 2 / 3; }
.card-row3-col3 { grid-area: 1 / 3 / 2 / 4; }
.card-row3-col4 { grid-area: 1 / 4 / 2 / 5; }
  .flexcol{
    flex: 1;
  }
  .hotel-result-dummy-section{
    background-image: linear-gradient(to right,rgb(118,18,81),rgb(145,32,73),rgb(167,43,67));
    height: 25vh;
  }
  .offer-text{
    font-size: 16px;
    font-weight: bold;
  }
  .offer-text-value{
    font-size: 12px;
  }
  .dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  
  .dialog table {
    width: 100%;
  }
  
  .dialog table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .dialog table td:first-child {
    font-weight: bold;
  }
  
  .dialog-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .dialog-buttons button {
    margin-left: 10px;
  }
  