/* main container */
.buscontainer{
    height: auto;
    width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    background-attachment: fixed;
  }
  /* overflow horizontal scrollbar remove */
  body{
  overflow-x: hidden;
  }
  /* firstrow image */
  .buscontainerfirstrow {
    position: relative;
    width: 100%;
    min-height: 80vh;
  background-image: url('../../Images/busbackground1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -2;
  }
  
  .buscontainerfirstrow::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity as needed */
    z-index: -1; /* Ensure the overlay is above the background image */
  }
  
  /* search button design */
  .navbarbutton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    will-change: box-shadow,transform;
    background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
    box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
    padding: 0 2em;
    border-radius: 0.3em;
    color: #fff;
    height: 2.6em;
    text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
    transition: box-shadow 0.15s ease, transform 0.15s ease;
    font-size: 1.2rem;
  }
  .navbarbutton:hover {
  box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
  transform: translateY(-0.1em);
  }
  .navbarbutton:active {
  box-shadow: inset 0px 0.1em 0.6em #3c4fe0;
  transform: translateY(0em);
  }
  
  /* third row offer section */
  /* third row offer section */
 .buscontainerthirdrow{
  background-color: white;
  padding: 20px;
}

  
  .offerimg{
    height: 100%;
    width: 100%;
  }
  
  .faqcontainer{
    background-color: white;
  }
  summary {
    font-size: 1rem;
    font-weight: 600;
    background-color: #fff;
    color: purple;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
  }
  details > summary::after {
    position: absolute;
    content: "+";
    right: 20px;
  }
  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }
  @keyframes sweep {
    0%    {opacity: 0; margin-top: -10px}
    100%  {opacity: 1; margin-top: 0px}
  }
  .aboutuscontainer{
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 50px;
    background-color: white;
  }
  
  
  
  .aboutuscontainerrow1 { grid-area: 1 / 1 / 2 / 2; }
  .aboutuscontainerrow2 { grid-area: 1 / 2 / 2 / 3;
  background-image: url('../../assets/about-banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px;
  }
  .aboutustitle1{
    
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; /* Use the actual font name or fallback to a generic sans-serif font */
    color: #4624ae; /* Use the specific color value instead of --jet */
    font-size: 3rem; /* Use a specific font size instead of --fs-3 */
    font-weight: 500; /* Use a specific font weight instead of --fw-500 */
    
  }
  .aboutussubtitle1{
    text-align: start;
    color: gray;
  }
  
/* popular flight route section start */
.populerbusroutecontainer{
  margin-left: 8vw;
  margin-right: 8vw;
  box-shadow: 0 0 3px 3px gray; 
  border: 1px solid gray;
  margin-top: 10vh;
  gap: 20px;
}
.populerbusroute{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  padding: 10px;
}
.populerbusrouteheading{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.8rem;
  padding: 10px;
  background-color: white;
}

.populerbusroutebox{

  width: 320px; 
  height: 70px;
  margin: 10px;
background: transparent;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
grid-template-columns: 0.5fr 1fr;
grid-template-rows: 1fr;
}
.populerbusrouteboxcol1 { grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
  align-items: center;

}
.populerbusrouteboxcol2 { 
  grid-area: 1 / 2 / 2 / 3;
 padding: 10px;
 text-align: start;
}
.populerflightimage{
  height: 70px;
  width: 70%;
  border-radius: 50%;
}
.bus-flightcol{
  width: 200px;
    padding: 10px;
  }

  .bus-second-row{
    margin-left: 8%;
    margin-right:8%;
    z-index: 5;
    margin-top: -30vh;
    margin-bottom: 10vh;
  }
  @media screen and (max-width:900px) {
  
  .aboutuscontainer{
    grid-template-columns: 1fr;
  }
  .aboutuscontainerrow1,.aboutuscontainerrow2{
    grid-area: auto;
  }
  .aboutuscontainerrow2{
    height: 80vh;
  }
  
  .buscontainerthirdrow {
    grid-template-columns: 1fr;
    }
    .buscontainerthirdrowcol1,.buscontainerthirdrowcol2,.buscontainerthirdrowcol3{
       grid-area: auto;
    }
  }
  @media (max-width:760px) {
   
    .buscontainerfirstrow {
      margin-top: -10vh;
      position: relative;
      width: 100%;
      min-height: 50vh;
    background-image: url('../../Images/busbackground1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: -2;
    }
    .bus-second-row{
      margin-left: 1vw;
      margin-right: 1vw;
      background-color: rgb(237, 238, 243);
      margin-top: -10vh;
      margin-bottom: 10vh;
     }
     .bus-flightcol{
       width: 165px;
         padding: 5px;
       }
       .FourthContainer{
        margin-top: -10vh;
       }

    .aboutuscontainer{
      grid-template-columns: 1fr;
    }
    .aboutuscontainerrow1,.aboutuscontainerrow2{
      grid-area: auto;
    }
   
  }
 