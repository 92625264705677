.payment-container {
    padding: 100px;
    background-color: #f4f4f9; /* Light gray background for the container */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px; /* Rounded corners for the container */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  }
  
  .payment-container input[type="number"] {
    width: 50%; /* Responsive width */
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Makes sure padding doesn't affect width */
    font-size: 16px; /* Larger font size for better readability */
  }
  
  .payment-container button {
    width: 50%; /* Responsive width */
    background-color: #8b2cc2; /* Green background */
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px; /* Matches input font size */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .payment-container button:hover {
    background-color: #7a28a9;  /* Slightly darker green on hover */
  }
  
  .payment-container button:disabled {
    background-color: #cccccc; /* Grey out the button when disabled */
    cursor: not-allowed; /* Show a not-allowed cursor on hover when disabled */
  }
  