/* main container */
.traincontainer{
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
background-color: #fff;
  background-attachment: fixed;
}

/* overflow horizontal scrollbar remove */
body {
  overflow-x: hidden;
}


/* firstrow image */
.traincontainerfirstrow {
  width: 100%;
  min-height: 95vh;
  background-image: url('../Images/train.png');
  background-size: 100%;
  background-repeat: no-repeat;
 margin-top: -15vh;
 border-image: fill 0 linear-gradient(#0001,#00000075); }


/* search button design */
.navbarbutton {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  will-change: box-shadow,transform;
  background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
  padding: 0 2em;
  border-radius: 0.3em;
  color: #fff;
  height: 2.6em;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  font-size: 1.2rem;
}

.navbarbutton:hover {
  box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
  transform: translateY(-0.1em);
}

.navbarbutton:active {
  box-shadow: inset 0px 0.1em 0.6em #3c4fe0;
  transform: translateY(0em);
}


/* third row offer section  offer carousel start */
.traincontainerthirdrow{
 background-color: white;
 padding: 20px;
}
.traincontainerthirdrowcol1 {
 width: 100%;
height: 45vh;
background-color: white;
}
/* third row offer section  offer carousel end */



.offerimg{
  height: 100%;
  width: 100%;
}

.traincontainersecondrowcol2row2text{
  font-size: 1rem;
}

.faqcontainer{
  background-color: white;
}

summary {
  font-size: 1rem;
  font-weight: 600;
  background-color: #fff;
  color: purple;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative;
}

details > summary::after {
  position: absolute;
  content: "+";
  right: 20px;
  color: red;
}

details[open] > summary::after {
  position: absolute;
  content: "-";
  right: 20px;
}

details > summary::-webkit-details-marker {
  display: none;
}

details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; margin-top: -10px}
  100%  {opacity: 1; margin-top: 0px}
}

/* YourComponent.css */

/* .FourthContainer {
 display: flex;
 flex-direction: column; 
 background-color: #f7f6ff; 
 margin-left: 5%;
 margin-right: 5%;
 border-radius: 5px;
} */

/* .FourthContainerRow1,
.FourthContainerRow2 {
 flex: 1; 
 margin: 10px; 
}
.FourthContainerRow1 {
 display: flex;
 justify-content: center;
 align-items: center; 
}
.FourthContainerRow2 {

 display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-template-rows: repeat(2, 1fr);
 grid-column-gap: 10px;
 grid-row-gap: 10px;
 }
 .FourthContainerRow2box1,.FourthContainerRow2box2,.FourthContainerRow2box3,
   .FourthContainerRow2box4,.FourthContainerRow2box5,.FourthContainerRow2box6{
border-radius: 5px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
border: 1.2px solid rgb(5, 46, 5);
display: grid;
grid-template-columns: 0.35fr 1fr 0.1fr;
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
   }
.FourthContainerRow2box1:hover,.FourthContainerRow2box2:hover,.FourthContainerRow2box3:hover,
.FourthContainerRow2box4:hover,.FourthContainerRow2box5:hover,.FourthContainerRow2box6:hover{
box-shadow: 0 0 8px rgb(8, 8, 8);
box-shadow: 0 0 8px rgb(3, 27, 91) inset;
} */


   /* .subbox1 { grid-area: 1 / 1 / 2 / 2;
 
   display: flex;
   justify-content: center;
   align-items: center;
   } */
   /* .trainboximg{
  height: 90%;
  width: 90%;
  padding: 8px;
   }
   .subbox2text1{
     font-size: 1rem;
     color: purple;
   }
   .subbox2text2{
     font-size: 0.8rem;
   } */


.FourthContainer {
 
 background-color: #f7f6ff; 
 margin-left: 5vw;
 margin-right: 5vw;
 border-radius: 5px;
 display:grid;
 grid-template-columns: 1fr;
 grid-template-rows: 0.5fr 1fr;


}
.FourthContainerRow1{
grid-area: 1 / 1 / 2 / 2;
display: flex;
justify-content: center;
align-items: center;
padding: 5px;
}
.FourthContainerRow2{
grid-area: 2 / 1 / 3 / 2;
display: flex;
flex-wrap: wrap;
gap:30px;
padding-left: 20px;padding-right: 20px;
}
.FourthContainerRow2box{
 width: 400px;
 display: grid;
 grid-template-columns: 0.3fr 0.8fr;
 grid-template-rows: 1fr;
 grid-column-gap: 0px;
 grid-row-gap: 0px;
 text-align: start;
}

 .subbox1 {
    grid-area: 1 / 1 / 2 / 2;
   
   }
 .subbox2 { 
   grid-area: 1 / 2 / 2 / 3; 
 
 }

 .subbox2text1{
   font-size: 1.4rem;
 }
.trainboximg{
 height: 70%;
 width: 70%;
}
.train-flightcol{
  width: 200px;
    padding: 10px;
  }
 
 
.button-label {
 display: inline-block;
 margin: 7px;
 padding: 5px;
 cursor: pointer;
 border: 1px solid #ccc;
 border-radius: 5px;
 transition: background-color 0.3s ease;
}

.button-label.clicked {
 background-color: green;
 color: white;
}
.selecttag{
 appearance: none;
 border: none;
 background-color: rgb(240, 240, 255);
 padding: 0.8rem;
 margin-left: 1rem;
 border-radius: 5px;
}
.selecttag2{
 appearance: none;
 border: none;
 background-color: rgb(240, 240, 255);
 padding: 0.8rem;
 border-radius: 5px;
}






/* popular flight route section start */
.populertrainroutecontainer{
 margin-left: 8vw;
 margin-right: 8vw;
 box-shadow: 0 0 3px 3px gray; 
 border: 1px solid gray;
 margin-top: 10vh;
 gap: 20px;
}
.populertrainroute{
 display: flex;
 flex-wrap: wrap;
 justify-content: space-around;
 background-color: white;
 padding: 10px;
}
.populertrainrouteheading{
 display: flex;
 justify-content: center;
 align-items: center;
 font-weight: 700;
 font-size: 1.8rem;
 padding: 10px;
}

.populertrainroutebox{

 width: 320px; 
 height: 70px;
 margin: 10px;
background: transparent;
 color: black;
 display: flex;
 align-items: center;
 justify-content: center;
 display: grid;
grid-template-columns: 0.5fr 1fr;
grid-template-rows: 1fr;
}
.populertrainrouteboxcol1 { grid-area: 1 / 1 / 2 / 2;
 display: flex;
 justify-content: center;
 align-items: center;

}
.populertrainrouteboxcol2 { 
 grid-area: 1 / 2 / 2 / 3;
padding: 10px;
text-align: start;
}
.populerflightimage{
 height: 70px;
 width: 70%;
 border-radius: 50%;
}



.train-second-row{
margin-left: 8%;
margin-right: 8%;
margin-top: -30vh;
margin-bottom: 16vh;
}


@media screen and (max-width:900px) {
  
  .aboutuscontainer{
    grid-template-columns: 1fr;
  }
  .aboutuscontainerrow1,.aboutuscontainerrow2{
    grid-area: auto;
  }
  .aboutuscontainerrow2{
    height: 80vh;
  }

  .traincontainerthirdrow {
     grid-template-columns: 1fr;
     }
     .traincontainerthirdrowcol1,.traincontainerthirdrowcol2,.traincontainerthirdrowcol3{
        grid-area: auto;
     }
}


@media (max-width:760px) {

 .traincontainerfirstrow {
   width: 100%;
   min-height: 50vh;
   background-size: 100% 100%;
   }
 
  .train-second-row{
   margin-left: 2vw;
   margin-right: 2vw;
   background-color: rgb(237, 238, 243);
   margin-top: -10vh;
   margin-bottom: 10vh;
  }
  .train-flightcol{
    width: 165px;
      padding: 5px;
    }
    
.FourthContainer{
  grid-template-columns: 1fr;
}
.FourthContainerRow1,.FourthContainerRow2{
grid-area: auto;
}
.FourthContainerRow1{
  margin-top: -30vh;

}
.FourthContainerRow2{
  margin-top: -30vh;
}
 .FourthContainerRow2box{
  width: 300px;
  display: grid;
 }
 
  .subbox1 {
     grid-area: 1 / 1 / 2 / 2;
    
    }
  .subbox2 { 
    grid-area: 1 / 2 / 2 / 3; 
  
  }
 .trainboximg{
  height: 50%;
  width: 70%;
 }

}