.offercontainer{
    height: auto;
    width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
  }
  .offercontainerfirstrow{
   background-image: url('../Images/offersbackground-overlay\ \(2\).png');
   background-size: 100% 100%;
   background-repeat: no-repeat;
   min-height: 80vh;
  }
  .carousl {
    width: 100vw;
    min-height: 300px;
    background: white;
    display: flex;
    align-items: center;
    margin-bottom: -20%;
 
  }
  .carodiv1,
  .carodiv3 {
    flex: 0 0 10vw;
    background:transparent;
    padding: 10px;
    box-sizing: border-box;
  }
  .carodiv2 {
    flex: 0 0 80vw;
    background: transparent;
    overflow: hidden; 
    position: relative;
  }
  .caroimg{
    height: 300px;
    width: 480px;
    border-radius: 5px;
    margin: 5px;
  }
 
  .bgimg{
    height: 72vh;
    width: 90%;
  }
  
  .offercontainercards { 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 50px;
  }
  
  .offercard2 {
    width: 300px;
    height: 200px; /* Initial height */
    margin: 10px;
    padding: 15px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.4s ease; /* Add transition for smooth effect */
}
.offercard2:hover{
    border: 1.5px solid navy;
    width: 320px;
    height: 220px;
}
  .offerimg{
    height: 100%;
    width: 100%;
  }