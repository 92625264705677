/* main container */
.hotelcontainer{
    height: auto;
    width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../Images/backgroundimagepink.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
  
  /* overflow horizontal scrollbar remove */
  body {
    overflow-x: hidden;
  }
  
  /* firstrow image */
  .hotelcontainerfirstrow {
    position: relative; /* Ensure relative positioning for the pseudo-element */
    width: 100%;
    min-height: 85vh;
    background-image: url('../../Images/hotelimage.jpeg');
    background-size: cover; /* Use cover instead of 100% 100% for better responsiveness */
    background-repeat: no-repeat;
    margin-top: -10vh;
    z-index: -2;
  }
  
  .hotelcontainerfirstrow::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity as needed */
    z-index: -1; /* Ensure the overlay is above the background image */
  }
  .hotel-secondrow{
    margin-top: -20vh;
    margin-bottom: 10vh;
    background: transparent;
    margin-left: 10%;
    margin-right: 10%;
  }
  

  /* search button design */
  .navbarbutton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    will-change: box-shadow,transform;
    background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
    box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
    padding: 0 2em;
    border-radius: 0.3em;
    color: #fff;
    height: 2.6em;
    text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
    transition: box-shadow 0.15s ease, transform 0.15s ease;
    font-size: 1.2rem;
  }
  
  .navbarbutton:hover {
    box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
    transform: translateY(-0.1em);
  }
  
  .navbarbutton:active {
    box-shadow: inset 0px 0.1em 0.6em #3c4fe0;
    transform: translateY(0em);
  }
  
  /* third row offer section */
  .hotelcontainerthirdrow{

    height: 45vh;
    padding: 5px;
background-color: white;
    gap: 20px;
margin-bottom: 10vh;
  }

  .hotelcontainerfourthrow{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 1fr;
    background-color: #fae6db;
    padding: 50px;
  }
  
  .hotelcontainerfourthrowrow1 { 
    grid-area: 1 / 1 / 2 / 2; 
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 2px 2px 2px 2px black;
    background-color: white;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #3498db; 
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .hotelcontainerfourthrowrow2 { 
    grid-area: 2 / 1 / 3 / 2; 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .offercard {
    width: 300px;
    height: 200px; /* Initial height */
    margin: 10px;
    padding: 15px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    transition: all 0.4s ease; /* Add transition for smooth effect */
  }
  
  .offercard:hover {
    width: 340px; /* Increased width on hover */
    height: 250px; /* Increased height on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Example changed box shadow on hover */
  }
  
  .offerimg{
    height: 100%;
    width: 100%;
  }
  
  .hotelcontainersecondrowcol2row2text{
    font-size: 1rem;
  }
  
  .faqcontainer{
    background-color: white;
  }
  
  summary {
    font-size: 1rem;
    font-weight: 600;
    background-color: #fff;
    color: purple;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
  }
  
  details > summary::after {
    position: absolute;
    content: "+";
    right: 20px;
  }
  
  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }
  
  details > summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0%    {opacity: 0; margin-top: -10px}
    100%  {opacity: 1; margin-top: 0px}
  }
  
  .aboutuscontainer{
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 50px;
    background-color: white;
  }
  
  .aboutuscontainerrow1 { grid-area: 1 / 1 / 2 / 2; }
  .aboutuscontainerrow2 { 
    grid-area: 1 / 2 / 2 / 3;
    background-image: url('../../assets/about-banner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px;
  }
  
  .aboutustitle1{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #4624ae;
    font-size: 3rem;
    font-weight: 500;
  }
  
  .aboutussubtitle1{
    text-align: start;
    color: gray;
  }
  /* search bar styling */
 
  .button-label {
   display: inline-block;
   margin: 7px;
   padding: 5px;
   cursor: pointer;
   border: 1px solid #ccc;
   border-radius: 5px;
   transition: background-color 0.3s ease;
 }
 
 .button-label.clicked {
   background-color: green;
   color: white;
 }
 .selecttag{
   appearance: none;
   border: none;
   background-color: rgb(240, 240, 255);
   padding: 0.8rem;
   margin-left: 1rem;
   border-radius: 5px;
 }
 .selecttag2{
   appearance: none;
   border: none;
   background-color: rgb(240, 240, 255);
   padding: 0.8rem;
   border-radius: 5px;
 }
  @media screen and (max-width:900px) {
    .hotelcontainersecondrow{
      grid-template-columns: 1fr;
    }
    .hotelcontainersecondrowcol1,.hotelcontainersecondrowcol2,.hotelcontainersecondrowcol3{
      grid-area: auto;
      margin-top: -12vh;
    }
    .hotelcontainersecondrowcol2{
      grid-template-columns: 1fr;
    }
    .hotelcontainersecondrowcol2row1,.hotelcontainersecondrowcol2row2,.hotelcontainersecondrowcol2row3,.hotelcontainersecondrowcol2row4{
      grid-area: auto;
    }
    .hotelcontainersecondrowcol2row2{
      display: grid;
      grid-template-columns: 0.6fr 0.2fr 0.6fr repeat(3, 0.4fr);
      grid-template-rows: 1fr;
    }
  
    .aboutuscontainer{
      grid-template-columns: 1fr;
    }
    .aboutuscontainerrow1,.aboutuscontainerrow2{
      grid-area: auto;
    }
    .aboutuscontainerrow2{
      height: 80vh;
    }
  
    .offercard {
      width: 280px;
      margin: 10px;
      padding: 15px;
      border: 1px solid #ddd;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
      background-color: #fff;
      border-radius: 10px;
      transition: all 0.4s ease; /* Add transition for smooth effect */
    }
  
    .offercard:hover {
      width: 300px; /* Increased width on hover */
      height: 250px; /* Increased height on hover */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Example changed box shadow on hover */
    }
    .hotelcontainerthirdrow {
       grid-template-columns: 1fr;
       }
       .hotelcontainerthirdrowcol1,.hotelcontainerthirdrowcol2,.hotelcontainerthirdrowcol3{
          grid-area: auto;
       }
 
       .hotelcontainersecondrowcol2row2 {
         grid-template-columns: 1fr 1fr 1fr; /* 3 columns in one row */
         grid-template-rows: repeat(2, auto); /* 2 rows */
       }
     
       /* Apply grid areas to each div within the container */
       .tcsrc2r2div2 {
         grid-area: 1 / 2 / 2 / 3;
         display: flex;
         justify-content: center;
         align-items: center;
         border-top: 1px solid navy;
         border-bottom: 1px solid navy;
       }
     
       .tcsrc2r2div3 {
         grid-area: 1 / 3 / 2 / 4;
         border: 1px solid navy;
         z-index: 0;
         padding: 5px;
         margin-left: -8vw;
       }
     
       .tcsrc2r2div4 {
         grid-area: 2 / 1 / 3 / 2; /* Adjusted grid area to span 3 columns */
         border: 1px solid navy;
         padding: 10px;
       }
     
       .tcsrc2r2div5 {
         grid-area: 2 / 2 / 3 / 3; /* Adjusted grid area to span 3 columns */
         border: 1px solid navy;
         padding: 10px;
       }
     
       .tcsrc2r2div6 {
         grid-area: 2 / 3 / 3 / 4; /* Adjusted grid area to span 3 columns */
         border: 1px solid navy;
         padding: 10px;
       }
     
       .tcsrc2r2div1 {
         grid-area: 1 / 1 / 2 / 2;
         border: 1px solid navy;
         position: relative;
         line-height: 1;
         text-align: start;
         z-index: 0;
         padding: 5px;
         margin-right: -8vw;
       }
       
  }
  @media (max-width:760px) {
    .hotelcontainersecondrowcol2row2{
      display: grid;
      grid-template-columns: 0.4fr 0.1fr 0.4fr repeat(3, 0.2fr);
      grid-template-rows: 1fr;
    }
    .hotelcontainersecondrowcol2row2text{
      font-size: 0.7rem;
    }
    .hotelcontainerfirstrow{
      margin-top: -10vh;
    }
    .aboutuscontainer{
      grid-template-columns: 1fr;
    }
    .aboutuscontainerrow1,.aboutuscontainerrow2{
      grid-area: auto;
    }
    .hotelcontainerfourthrowrow2 { 
      grid-area: 2 / 1 / 3 / 2; 
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      margin-bottom: -10vh;
      margin: -50px;
    }
    .offercard {
      width: 240px;
      padding: 10px;
      border: 1px solid #ddd;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
      background-color: #fff;
      border-radius: 10px;
      transition: all 0.4s ease; /* Add transition for smooth effect */
    }
    
    .offercard:hover {
      width: 260px; /* Increased width on hover */
      height: 220px; /* Increased height on hover */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Example changed box shadow on hover */
    }
    .hotelcontainerthirdrow {
       grid-template-columns: 1fr;
       }
       .hotelcontainerthirdrowcol1,.hotelcontainerthirdrowcol2,.hotelcontainerthirdrowcol3{
          grid-area: auto;
       }
       .hotelcontainersecondrowcol2row2 {
         grid-template-columns: 1fr 1fr 1fr; /* 3 columns in one row */
         grid-template-rows: repeat(2, auto); /* 2 rows */
       }
       /* Apply grid areas to each div within the container */
       .tcsrc2r2div2 {
         grid-area: 1 / 2 / 2 / 3;
         margin-left: 5px;
         display: flex;
         justify-content: center;
         align-items: center;
       }
     
       .tcsrc2r2div3 {
         grid-area: 1 / 3 / 2 / 4;
         border-right: 1px solid navy;
         border-top: 1px solid navy;
         border-bottom: 1px solid navy;
         z-index: 0;
       }
     
       .tcsrc2r2div4 {
         grid-area: 2 / 1 / 3 / 2; /* Adjusted grid area to span 3 columns */
         border: 1px solid navy;
       }
     
       .tcsrc2r2div5 {
         grid-area: 2 / 2 / 3 / 3; /* Adjusted grid area to span 3 columns */
         border: 1px solid navy;
       }
     
       .tcsrc2r2div6 {
         grid-area: 2 / 3 / 3 / 4; /* Adjusted grid area to span 3 columns */
         border: 1px solid navy;
       }
     
       .tcsrc2r2div1 {
         grid-area: 1 / 1 / 2 / 2;
         border-left: 1px solid navy;
         border-top: 1px solid navy;
         border-bottom: 1px solid navy;
         position: relative;
         line-height: 1;
         text-align: start;
         z-index: 0;
         padding: 5px;
       }
 
  }
  /* Media query for screens below 400px */
  @media screen and (max-width: 400px) {
    .hotelcontainersecondrowcol2row2 {
      grid-template-columns: 1fr 1fr 1fr; /* 3 columns in one row */
      grid-template-rows: repeat(2, auto); /* 2 rows */
    }
    .hotelcontainersecondrow{
      margin-top: -22vh;
    }
    /* Apply grid areas to each div within the container */
    .tcsrc2r2div2 {
      grid-area: 1 / 2 / 2 / 3;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .tcsrc2r2div3 {
      grid-area: 1 / 3 / 2 / 4;
      border-right: 1px solid navy;
      border-top: 1px solid navy;
      border-bottom: 1px solid navy;
      z-index: 0;
    }
  
    .tcsrc2r2div4 {
      grid-area: 2 / 1 / 3 / 2; /* Adjusted grid area to span 3 columns */
      border: 1px solid navy;
    }
  
    .tcsrc2r2div5 {
      grid-area: 2 / 2 / 3 / 3; /* Adjusted grid area to span 3 columns */
      border: 1px solid navy;
    }
  
    .tcsrc2r2div6 {
      grid-area: 2 / 3 / 3 / 4; /* Adjusted grid area to span 3 columns */
      border: 1px solid navy;
    }
  
    .tcsrc2r2div1 {
      grid-area: 1 / 1 / 2 / 2;
      border-left: 1px solid navy;
      border-top: 1px solid navy;
      border-bottom: 1px solid navy;
      position: relative;
      line-height: 1;
      text-align: start;
      z-index: 0;
    }
    .hotelcontainerfirstrow {
      width: 100%;
      min-height: 68vh;
   
    }
    .hotelcontainerfourthrowrow2 { 
      grid-area: 2 / 1 / 3 / 2; 
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      margin-bottom: -40vh;
    }
    .offercard {
      width: 300px;
      padding: 10px;
      border: 1px solid #ddd;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
      background-color: #fff;
      border-radius: 10px;
      transition: all 0.4s ease; /* Add transition for smooth effect */
    }
    
    .offercard:hover {
      width: 320px; /* Increased width on hover */
      height: 220px; /* Increased height on hover */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Example changed box shadow on hover */
    }
    .hotelcontainerthirdrow {
    grid-template-columns: 1fr;
    }
    .hotelcontainerthirdrowcol1,.hotelcontainerthirdrowcol2,.hotelcontainerthirdrowcol3{
       grid-area: auto;
    }
    summary {
      font-size: 1rem;
      font-weight: 600;
      background-color: #fff;
      color: #333;
      padding: 0.5rem;
      margin-bottom: 0.4rem;
      outline: none;
      border-radius: 0.25rem;
      text-align: left;
      cursor: pointer;
      position: relative;
    }
    details > summary::after {
      position: absolute;
      content: "+";
      right: 20px;
    }
    details[open] > summary::after {
      position: absolute;
      content: "-";
      right: 20px;
    }
    details > summary::-webkit-details-marker {
      display: none;
    }details[open] summary ~ * {
      animation: sweep .5s ease-in-out;
    }
    @keyframes sweep {
      0%    {opacity: 0; margin-top: -10px}
      100%  {opacity: 1; margin-top: 0px}
    }
  }
  