.carousel-image {
    margin: 7px;/* Add 10px margin between images */
  }
  .carouselcontainer{
z-index: -1;
  }
  .flightoffer{
    height: 300px;
    width: 500px;
  }
  .carousel2{
  
    gap: 10px;
    height: 250px;
    width: 300px;

  }
  .flightimage{
    height: 220px;
    width: 100%;
    padding: 5px;

    border-radius: 15px;
  }

  .slick-prev:before,
  .slick-next:before{
    color: black;
    z-index: 5;
  }
  .carousel3{
  
    gap: 10px;
    height:70vh;
    width: 100%;
z-index: 0;
  }
  .flightimage2{
    height: 80vh;
    width: 100%;
    padding: 5px;
  }.cta {
    display: flex;
    padding: 11px 33px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    color: white;
    background: #6225E6;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);
    border: none;
  }
  
  .cta:focus {
    outline: none;
  }
  
  .cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #FBC638;
  }
  
  .cta .second {
    transition: 0.5s;
    margin-right: 0px;
  }
  
  .cta:hover  .second {
    transition: 0.5s;
    margin-right: 45px;
  }
  
  .span {
    transform: skewX(15deg)
  }
  
  .second {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
  }
  
  .one {
    transition: 0.4s;
    transform: translateX(-60%);
  }
  
  .two {
    transition: 0.5s;
    transform: translateX(-30%);
  }
  
  .cta:hover .three {
    animation: color_anim 1s infinite 0.2s;
  }
  
  .cta:hover .one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
  }
  
  .cta:hover .two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
  }
  
  @keyframes color_anim {
    0% {
      fill: white;
    }
  
    50% {
      fill: #FBC638;
    }
  
    100% {
      fill: white;
    }
  }
  .alloffer{
    display: flex;
    justify-content: center;
  }
  .carouselcontainer{
    margin-left: 50px;
    margin-right: 50px;
  }
  .carousel-heading-text{
    color:purple;font-weight: 700;font-size:1.8rem;
  }
  @media screen and (max-width:760px) {
    .carouselcontainer {
      margin: 0 auto;
     width: auto;
    }
    
    .carousel-heading-text{
      color:purple;font-weight: 700;font-size:1.4rem;
    }
    .carouselcontainer .alloffer {
      text-align: center;
    }
    .flightimage{
      height: 150px;
      width: 200px;
      padding: 5px;
  
      border-radius: 15px;
    }
    .carousel2{
      gap: 10px;
      height: 160px;
      width: 200px;
    }
    .cta {
      display: flex;
      padding: 5px 10px;
      text-decoration: none;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      color: white;
      background: #6225E6;
      transition: 1s;
      box-shadow: 6px 6px 0 black;
      transform: skewX(-15deg);
      border: none;
    }
    
  }
