
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
 *{
     padding: 0;
     margin: 0;
     box-sizing: border-box;
 
 }
 body{
     font-family: 'Poppins', sans-serif;
 
     overflow-x: hidden;
    
 }
 ul{
     list-style: none;
 }
 a{
     text-decoration: none;
 }
 .navlogo-img{
     width: 80%;
     height: 90%;
     margin-top: -6vh;
     margin-left: 1vw;
 }
 .newheadercontainer{
    
    z-index: 10;
     margin: 0 auto;
     display: flex;
     position: absolute;
     width: 100%;
 background: transparent;
 
 }
 .marquee-content{
     color: white;
     font-weight: 700;
 }
 .topheader{
  
     margin: 0 auto;
     display: flex;
     position: relative;
     width: 100%;
     background: rgb(8, 8, 70);
 min-height: 50px;
 }
 .newheaderlogo-container {
     flex: 0;
     display: flex;
     justify-content: center;
     align-items: center;
     background:rgb(8, 8, 70);

     min-width: 10%;
     margin-left: 0; 
     border-radius: 0 0 90px 0;
 }
 /* menu bar css start */
 .nav-links {
     display: flex;
     justify-content: space-between;
   }
   
   .left-links,
   .right-links {
     display: flex;
     list-style: none;
     padding: 0;
     margin: 0;
   }
   
   .nav-link {
     margin-right: 20px;
     position: relative;
   }
   
   .nav-link:last-child {
     margin-right: 0;
   }
   /* menubar css end */
 .topdiv {
     width: 100vw;
     min-height: 50px;
     background: rgb(8, 8, 70);
     display: flex;
   }
   .topdiv1,
   .topdiv3 {
     flex: 0 0 20vw;
     background:transparent;
     padding: 10px;
     box-sizing: border-box;
   }
   .topdiv2 {
     flex: 1; 
     background: transparent;
     overflow: hidden; 
     position: relative;
   }
 .nav-btn{
     flex: 3;
     display: flex;
 }
 .nav-links{
     flex: 2;
 }
 .logo{
     color: #fff;
     font-size: 1.1rem;
     font-weight: 600;
     letter-spacing: 2px;
     text-transform: uppercase;
     line-height: 3rem;
 }
 .logo span{
     font-weight: 300;
 }
 .btn{
     display: inline-block;
     padding: .5rem 1.3rem;
     font-size: .8rem;
     border: 2px solid #fff;
     border-radius: 2rem;
     line-height: 1;
     margin: 0 .2rem;
     transition: .3s;
     text-transform: uppercase;
 }
 .btn.solid, .btn.transparent:hover{
     background-color: #fff;
     color: #69bde7;
 }
 .btn.transparent, .btn.solid:hover{
     background-color: transparent;
     color: #fff;
 }
 .nav-links > ul{
     display: flex;
     justify-content: flex-end;
     align-items: center;
 }
 .nav-link{
     position: relative;
 }
 .nav-link > a{
     line-height: 3rem;
     color: #fff;
     padding: 0 .4rem;
     letter-spacing: 0.8px;
     font-size: .90rem;
     display: flex;
     align-items: center;
     justify-content: space-between;
     transition: .5s;
 }
 .nav-link > a > i{
     margin-left: .2rem;
 }
 .nav-link:hover > a{
     background-color: #717b7f;
 }
 .nav-link > a:hover {
     background: rgb(8, 8, 70);
     color: white;
   }
 .dropdown{
     position: absolute;
     top: 100%;
     left: 0;
     width: 14rem;
  
     opacity: 0;
     pointer-events: none;
 
 }
 .dropdown ul{
     position: relative;
 }
 .dropdown-link > a{
     display: flex;
     background-color: #f8f8fd;
     color: #3498db;
     padding: 0.625rem 0.75rem;
     font-size: 14px;
     align-items: center;
     justify-content: space-between;
     transition: .3s;
     margin-top: -8px;
 }
 .dropdown-link:hover > a{
     background: radial-gradient(100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100%);
     color: #fff;
 }
 .dropdown-link:not(:nth-last-child(2)){
     border-bottom: 1px solid #efefef;
 }
 
 .arrow{
     position: absolute;
     width: 11px;
     height: 11px;
     top: -5.5px;
     left: 32px;
     background-color: #fff;
     transform: rotate(45deg);
     cursor: pointer;
     transition: .3s;
     z-index: -1;
 }
 .dropdown-link:first-child:hover ~ .arrow{
     background: radial-gradient(100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100%);
 }
 .dropdown-link{
     position: relative;
 }
 .dropdown.second{
     top: 0;
     left: 100%;
     padding-left: .8rem;
     cursor: pointer;
     transform: translateX(10px);
     padding-top: 2vh;
 }
 .dropdown.second .arrow{
     top: 10px;
     left: -5.5px;
 }
 .nav-link:hover > .dropdown,
 .dropdown-link:hover > .dropdown{
     transform: translate(0, 0);
     opacity: 1;
     pointer-events: auto;
 }
 .hamburger-menu-container{
     flex: 1;
     display: none;
     align-items: center;
     justify-content: flex-end;
     z-index: 5;
 }
 .hamburger-menu{
     width: 2.5rem;
     height: 2.5rem;
     display: flex;
     align-items: center;
     justify-content: flex-end;
     
 }
 .hamburger-menu div{
     width: 2rem;
     height: 3px;
     border-radius: 3px;
     background-color: #fff;
     position: relative;
     z-index: 1001;
     transition: .5s;
    
 }
 .hamburger-menu div:before,
 .hamburger-menu div:after{
     content: '';
     position: absolute;
     width: inherit;
     height: inherit;
     background-color: #fff;
     border-radius: 3px;
     transition: .5s;
 }
 .hamburger-menu div:before{
     transform: translateY(-7px);
 }
 .hamburger-menu div:after{
     transform: translateY(7px);
 }
 #check{
     position: absolute;
     top: 50%;
     right: 1.5rem;
     transform: translateY(-50%);
     width: 2.5rem;
     height: 2.5rem;
     z-index: 90000;
     cursor: pointer;
     opacity: 0;
     display: none;
 }
 #check:checked ~ .hamburger-menu-container .hamburger-menu div{
     background-color: transparent;
 }
 #check:checked ~ .hamburger-menu-container .hamburger-menu div:before{
     transform: translateY(0) rotate(-45deg);
 }
 #check:checked ~ .hamburger-menu-container .hamburger-menu div:after{
     transform: translateY(0) rotate(45deg);
 }
 
 
 .navbarbutton {
     margin-bottom: 10px; 
     margin-top: 10px; 
     padding: 8px 16px;
     border: none;
     border-radius: 4px;
  margin-right: 30px;
     cursor: pointer;
     will-change: box-shadow,transform;
     background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
     box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
     padding: 0 2em;
     border-radius: 0.3em;
     color: #fff;
     height: 2.6em;
     text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
     transition: box-shadow 0.15s ease, transform 0.15s ease;
 }
 .navbarbutton:hover {
   box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
   transform: translateY(-0.1em);
 }
 .navbarbutton:active {
   box-shadow: inset 0px 0.1em 0.6em #3c4fe0;
   transform: translateY(0em);
 }
 
 .navbarbutton1 {
  
     padding: 8px 16px;
     border: none;
     border-radius: 4px;
  margin-right: 30px;
     cursor: pointer;
     will-change: box-shadow,transform;
     background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #18d55a 100% );
     box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
     padding: 0 2em;
     border-radius: 0.3em;
     color: #fff;
     height: 2.6em;
     text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
     transition: box-shadow 0.15s ease, transform 0.15s ease;
 }
 .navbarbutton1:hover {
   box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
   transform: translateY(-0.1em);
 }
 .navbarbutton1:active {
   box-shadow: inset 0px 0.1em 0.6em #3c4fe0;
   transform: translateY(0em);
 }
 .topdiv3{
     display: flex;
     justify-content: flex-end;
     align-items: center;
   }
 @keyframes animation{
     from{
         opacity: 0;
         transform: translateY(10px);
     }
     to{
         opacity: 1;
         transform: translateY(0px);
     }
 }
 @media (max-width: 920px){
     .hamburger-menu-container{
         display: flex;
        
     }
 
     #check{
         display: block;
     }
  .nav-btn{
         position: fixed;
    
         height: 100%;
       
         right: 0; 
         width: 50%;
         background: transparent;
        
         flex-direction: column;
         align-items: center;
         justify-content: space-between;
         overflow-x: hidden;
         overflow-y: auto;
         transform: translateX(100%);
         transition: .65s;
     }
     #check:checked ~ .nav-btn{
         transform: translateX(0);
     }
     .container {
         padding: 0 1rem;
         margin: 0 auto;
         display: flex;
         position: relative;
     }
     #check:checked ~ .nav-btn .nav-link,
     #check:checked ~ .nav-btn .log-sign{
         animation: animation .5s ease forwards var(--i);
     }
 
     .nav-links{
         flex: initial;
         width: 100%;
     
     }
 
     .nav-links > ul{
         flex-direction: column;
     }
 
     .nav-link{
         width: 100%;
         opacity: 0;
         transform: translateY(15px);
     }
 
     .nav-link > a{
         line-height: 1;
         padding: 0.7rem 1rem;
     }
 
     .nav-link:hover > a{
         transform: scale(1);
         background-color: #717b7f;
     }
 
     .dropdown, .dropdown.second{
         position: initial;
         top: initial;
         left: initial;
         transform: initial;
         opacity: 1;
         pointer-events: auto;
         width: 100%;
         padding: 0;
         background-color: #323435;
         display: none;
     }
     .dropdown-link > a{
         background-color: transparent;
         color: #fff;
         padding: 1.2rem 2rem;
         line-height: 1;
     }
     .dropdown.second .dropdown-link > a{
         padding: 1.2rem 2rem 1.2rem 3rem;
     }
     .dropdown.second .dropdown.second .dropdown-link > a{
         padding: 1.2rem 2rem 1.2rem 4rem;
     }
 
     .dropdown-link:not(:nth-last-child(2)){
         border-bottom: none;
     }
 
     .arrow{
         z-index: 1;
         background-color: #454749;
         left: 10%;
         transform: scale(1.1) rotate(45deg);
         transition: .5s;
     }
 
     .nav-link:hover .arrow{
         background-color: #7d8386;
     }
 
     .dropdown .dropdown .arrow{
         display: none;
     }
 
     .dropdown-link:hover > a{
         background-color: #171818;
     }
 
     .dropdown-link:first-child:hover ~ .arrow{
         background-color: #1f2122;
     }
 
     .nav-link > a > i{
         font-size: 1.1rem;
         transform: rotate(-90deg);
         transition: .7s;
     }
 
     .dropdown i{
         font-size: 1rem;
         transition: .7s;
     }
 
     .log-sign{
         flex: initial;
         width: 100%;
         padding: 1.5rem 1.9rem;
         justify-content: flex-start;
         opacity: 0;
         transform: translateY(15px);
     }
 }
 
 @media (max-width: 1000px){
     .hamburger-menu-container{
         display: flex;
        
     }
 
     #check{
         display: block;
     }
  .nav-btn{
         position: fixed;
    
         height: 100%;
       
         right: 0; 
         width: 50%;
         background: transparent;
       
         flex-direction: column;
         align-items: center;
         justify-content: space-between;
         overflow-x: hidden;
         overflow-y: auto;
         transform: translateX(100%);
         transition: .65s;
     }
     #check:checked ~ .nav-btn{
         transform: translateX(0);
     }
     .container {
         padding: 0 1rem;
         margin: 0 auto;
         display: flex;
         position: relative;
     }
     #check:checked ~ .nav-btn .nav-link,
     #check:checked ~ .nav-btn .log-sign{
         animation: animation .5s ease forwards var(--i);
     }
 
     .nav-links{
         flex: initial;
         width: 100%;
         margin-top: 4vh;
     
     }
 
     .nav-links > ul{
         flex-direction: column;
     }
 
     .nav-link{
         width: 100%;
         opacity: 0;
         transform: translateY(15px);
     }
 
     .nav-link > a{
         line-height: 1;
         padding: 0.7rem 1rem;
     }
 
     .nav-link:hover > a{
         transform: scale(1);
         background-color: #717b7f;
     }
 
     .dropdown, .dropdown.second{
         position: initial;
         top: initial;
         left: initial;
         transform: initial;
         opacity: 1;
         pointer-events: auto;
         width: 100%;
         padding: 0;
         background-color: #323435;
         display: none;
         z-index: 100;
     }
 
     .nav-link:hover > .dropdown,
     .dropdown-link:hover > .dropdown{
         display: block;
     }
 
     .nav-link:hover > a > i,
     .dropdown-link:hover > a > i{
         transform: rotate(360deg);
     }
 
     .dropdown-link > a{
         background-color: transparent;
         color: #fff;
         padding: 1.2rem 2rem;
         line-height: 1;
     }
 
     .dropdown.second .dropdown-link > a{
         padding: 1.2rem 2rem 1.2rem 3rem;
     }
 
     .dropdown.second .dropdown.second .dropdown-link > a{
         padding: 1.2rem 2rem 1.2rem 4rem;
     }
 
     .dropdown-link:not(:nth-last-child(2)){
         border-bottom: none;
     }
 
     .arrow{
         z-index: 1;
         background-color: #454749;
         left: 10%;
         transform: scale(1.1) rotate(45deg);
         transition: .5s;
     }
 
     .nav-link:hover .arrow{
         background-color: #7d8386;
     }
 
     .dropdown .dropdown .arrow{
         display: none;
     }
 
     .dropdown-link:hover > a{
         background-color: #171818;
     }
 
     .dropdown-link:first-child:hover ~ .arrow{
         background-color: #1f2122;
     }
 
     .nav-link > a > i{
         font-size: 1.1rem;
         transform: rotate(-90deg);
         transition: .7s;
     }
 
     .dropdown i{
         font-size: 1rem;
         transition: .7s;
     }
 
     .log-sign{
         flex: initial;
         width: 100%;
         padding: 1.5rem 1.9rem;
         justify-content: flex-start;
         opacity: 0;
         transform: translateY(15px);
     }
 }
 
 @media (max-width: 620px) {
   
     .navlogo-img{
         width: 90%;
         height: 80%; 
         margin-top: -2vh;
         margin-left: -1vw;
     }
     .newheadercontainer{
        
         margin: 0 auto;
         display: flex;
         position: relative;
         width: 77%;
         background: transparent;
         flex-wrap: wrap; 
     
     }
     .topheader{
         margin: 0 auto;
         display: flex;
         position: relative;
         width: 100%;
         background: rgb(8, 8, 70);
     min-height: 50px;
     }
     .newheaderlogo-container {
         flex: 0;
         display: flex;
         justify-content: center;
         align-items: center;
         background: rgb(8, 8, 70);
         width: 12vw;
         min-width: 100px;
         margin-left: -12vw; 
         border-radius: 0 0 90px 0;
         margin-top: -10px;
     }
     .topdiv {
         width: 100%;
     min-height: auto;
     background: rgb(8, 8, 70);
     display: flex;
       }
       .topdiv1,
       .topdiv3 {
         
         background:transparent;
         padding: 10px;
         box-sizing: border-box;
       }
       
       .topdiv2 {
         flex: 1; 
         background: transparent;
         overflow: hidden; 
         position: relative;
       }
 }