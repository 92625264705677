customer-service-container{
 background-color: black;

}
.dummy-section{
    height: 40vh;
    /* background-image: linear-gradient(to right,rgb(35,117,239),rgb(98,196,245),rgb(143,253,249)); */
    background-image: linear-gradient(to right,rgb(118,18,81),rgb(145,32,73),rgb(167,43,67));
}
.customer-service-content{
    margin-left: 10%;
    margin-right: 10%;
    background-color: white;
    margin-top: -20vh;
    box-shadow: 0 0 12px black;
    text-align: start;
    padding: 20px;
    margin-bottom: 10vh;
}
.third-row{
    margin-left: 5%;
    margin-right: 5%;
}
.customer-service-content-tagline{
    padding: 20px;
    box-shadow: 0 0 5px blue;
    border-radius: 5px;
    margin-top: -10vh;
    background: rgb(239,239,239);
    margin-left: 5vw;
    margin-right: 5vw;
}
.customer-service-content-first-row{
    background-color: rgb(248, 236, 234);

    border: 0.5px solid gray;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 2px black;
    margin-top: 5vh;
}
.note-text{
    font-size: 18px;
    padding-left: 5px;
}
.note-subtext{
    font-size: 14px;
}
.customer-icon{
    color: red;
    filter: drop-shadow(0 0 10px rgba(248, 93, 3, 0.838));
}

.customer-service-content-first-row-subrow1{
    display: flex;
    
    justify-content: start;
    align-items: center;
}
.customer-text{
    font-size: 22px;
    font-weight: 650;
}
.ustomer-subtext{
    font-size: 14px;
}
.customer-service-content-second-row{
    margin-top: 20px;
    padding: 10px;

}
.customer-service-content-second-row-subrow2{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
   margin: 20px;
}
.cscol{
    padding: 20px;
}
.cscol {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.2fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .cscol-row1 { grid-area: 1 / 1 / 2 / 2; 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    }
    .cscol-row2 { grid-area: 2 / 1 / 3 / 2; }
.icon-cscol{

display: flex;
justify-content: center;
align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color:rgba(179, 187, 255, 0.673) ;
}
.icon-cscol2{
    display: flex;
justify-content: center;
align-items: center;
}

.icon-main{
    padding: 5px;
}
.customer-service-content-third-row{
    margin-top: 20px;
    padding: 20px;
}
/* faq style section */
.faqopen-blockquote {
    text-align: start;
    font-weight: 500;
  }
   /* AccountSetting style */

.account-setting-content{
    margin-left: 8%;
    margin-right: 8%;
    margin-top: -15vh;
    background-color: white;
    padding: 20px;
    margin-bottom: 10vh;

}

.header-text{
    margin-top: -8vh;
    display:flex;
    justify-content: start;
    align-items: center;
    color: white;
    margin-bottom: 4vh;

}
  
.account-button{
    display: flex;
    justify-content: start;
    align-items: center;
    
}
.assured-options {
    padding: 5px;
    text-align: start;
  }
  
  .assured-options span {
    cursor: pointer;
    margin-right: 10px;
  }
  
  .assured-options span:hover {
    text-decoration: underline;
    text-decoration-color: rgb(119,18,81);
    text-decoration-thickness: 3px;
    color: rgb(119,18,81);
  }
  .assured-hr{
    margin-top: 10px;
  }
  
  