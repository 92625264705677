

.footer {
    background-image: url('../assets/footer-bg.png');
    background-color: rgb(24, 23, 64);
    color: #fff;
    width: 100%;
    height: auto;
    margin-top: -1rem;
    margin-bottom: 0; /* Add this line to remove bottom margin */
    padding-bottom: 0; /* Add this line to remove bottom padding */
}
.footerfirstrow{
    display: grid;
grid-template-columns: 1fr 0.3fr;
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
height: auto;
border-bottom: 2px solid greenyellow;
}
.footericon{
    font-size: 2em;
}
.footericon1{
    font-size: 2em;
background-image: linear-gradient(to right top, rgb(246, 25, 25),rgb(134, 9, 134));
}
.footerfirstrowcol1{
    grid-area: 1 / 1 / 2 / 2;padding: 20px;
}
.footerfirstrowcol2{
    grid-area: 1 / 2 / 2 / 3;display: flex;
     justify-content: space-between; 
     align-items: center;padding: 50px;
}
.logo1{
    height: 100px;
    width: 190px;
}

.footersecondrow{
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
height: auto;

}

.footersecondrowcol1 { grid-area: 1 / 1 / 2 / 2; padding: 20px; }
.footersecondrowcol2 { grid-area: 1 / 2 / 2 / 3; padding: 20px; }
.footersecondrowcol4 { grid-area: 1 / 3 / 2 / 4; padding: 20px; }
.footersecondrowcol5 { grid-area: 1 / 4 / 2 / 5;  padding: 20px; }
.footerthirdrow{
    display: grid;
grid-template-columns: 1fr;
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
height: auto;
background-color: rgba(24, 24, 86, 0.751);
}
.footerthirdrowcol1{
    grid-area: 1 / 1 / 2 / 2;
}
.logoimg2{
    height: 60px;
    width: 60px;
  }
  .logoimg4{
    height: 65px;
    width: 65px;
  }
  .logoimg3{
    height: 70px;
    width: 140px;
  }
  .box .link_name{
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
  }
 .box .link_name::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 35px;
    background: #fff;
    color: white;
  }
  .footertitle{
    font-size: 0.8rem;
    color: white;

  }
  .bottom_text{
    max-width: 1250px;
    margin: auto;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
  }
  .policy_terms{
    padding-left: 20vw;
  }
@media screen and (max-width:900px) {
    .footerfirstrow{
        grid-template-columns: 1fr 1fr;
    }
    .footerfirstrowcol1,.footerfirstrowcol2{
        grid-area: auto;
    }
    .footerfirstrowcol2{
        padding: 15px;
    }
    .footerfirstrowcol1{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footericon{
        font-size: 25px;
    }
    .logo1{
        height: 50px;
        width: 100px;
    }
    .footersecondrow{
        grid-template-columns: 1fr;
    }
    .footersecondrowcol1,.footersecondrowcol2,.footersecondrowcol3,.footersecondrowcol4,.footersecondrowcol5{
        grid-area: auto;
    }
    .footerthirdrow{
        grid-template-columns: auto;
    }
    .footerthirdrowcol1{
        grid-area: auto;
    }
    .policy_terms{
      padding-left: 0px;
      }
}

   