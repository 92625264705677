
/* main container */
.flightcontainer{
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
background-color: rgb(235, 238, 242);
  background-attachment: fixed;
}
/* overflow horizontal scrollbar remove */
body{
overflow-x: hidden;
}
.flightcontainerfirstrow {
  width: 100%;
  min-height: 70vh;
  z-index: 0;
}
.image-slider{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(5, 3, 59, 0.3) 0px 18px 36px -18px inset;
  border-image: fill 0 linear-gradient(rgba(0, 0, 0, 0),#000000eb); 
 width: 100%;
  height: 80vh;
  background-size: 100% 100%;
  background-position: center;
}
.flightsearchcontainer{
  margin-left: 8%;
  margin-right: 8%;
  background-color: rgb(237, 238, 243);
  margin-top: -30vh;
  margin-bottom: 10vh;
}
.availablecontainer{
  padding: 10px;
}
.availablecontainerrow{
  display: flex;
  flex-wrap: wrap;
  /* background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9); */
  background-color: rgb(237, 238, 243);
}
.flight-flightcol{
width: 250px;
  padding: 10px;
}
/* search button design */
.navbarbutton {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  will-change: box-shadow,transform;
  background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
  box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
  padding: 0 2em;
  border-radius: 0.3em;
  color: #fff;
  height: 2.6em;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  font-size: 1.2rem;
}
.navbarbutton:hover {
box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0;
transform: translateY(-0.1em);
}
.navbarbutton:active {
box-shadow: inset 0px 0.1em 0.6em #3c4fe0;
transform: translateY(0em);
}

/* third row offer section  offer carousel start */
.flightcontainerthirdrow{
  background-color: white;
  padding: 20px;
}
.flightcontainerthirdrowcol1 {
  width: 100%;
height: 45vh;
background-color: white;
}
/* third row offer section  offer carousel end */

.button {
  padding: 10px 20px;
  background-color: #3498db; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.offerimg{
  height: 100%;
  width: 100%;
}
/* faq section start */
.faqcontainer {
  background-color: white;
  padding: 10px;
  margin-left: 8vw;
  margin-right: 8vw;
  box-shadow: 0 0 3px 3px gray; /* Change the values as needed */
  border: 1px solid gray;
  margin-top: 10vh;
}
.faqdetails{
  background-color: white;
  padding: 30px;
  margin-left: 8vw;
  margin-right: 8vw;
  box-shadow: 0 0 3px 3px gray; /* Change the values as needed */
  border: 1px solid gray;
  text-align: start;
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.faqdetailsh2 {
  color: #ff1100;
}

.faqdetailsul{
  list-style-type: none;
  padding: 0;
}

.faqdetailsli {
  margin-bottom: 15px;
}

.faqdetailsli::before {
  content: "\2022";
  color: #007BFF;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.faqli {
  margin-bottom: 5px;
}

.faqli::before {
  content: "\2022";
  color: #007BFF;
  display: inline-block;
  width: 0.8em;
  margin-left: 1em;
}
blockquote {
  border-left: 3px solid #007BFF;
  padding-left: 15px;
  margin-left: 0;
  color: #555;
}
summary {
  font-size: 1rem;
  font-weight: 600;
  background-color: #fff;
  color: purple;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: start;
  cursor: pointer;
  position: relative;
}
details > summary::after {
  position: absolute;
  content: "+";
  right: 20px;
}
.faqopen{
  text-align: center;
  padding: 5px;
}
details[open] > summary::after {
  position: absolute;
  content: "-";
  right: 20px;
}
details > summary::-webkit-details-marker {
  display: none;
}details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}
@keyframes sweep {
  0%    {opacity: 0; margin-top: -10px}
  100%  {opacity: 1; margin-top: 0px}
}
.aboutuscontainer{
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 50px;
  background-color: white;
  text-align: start;
}

.point {
  margin-bottom: 20px;
}

.point h3 {
  color: #007BFF;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.point p {
  margin: 0;
  line-height: 1.6;
}

.icon {
  margin-right: 10px;
  font-size: 24px;
  color: #007BFF;
  animation: rotateIcon 2s linear infinite; /* Adjust the duration and timing function as needed */
  transform-origin: center; /* Set the rotation point to the center */
}

.icon:hover {
  font-size: 30px;
  color: #007BFF;
  animation: none; /* Disable rotation on hover */
}


.aboutuscontainerrow1 { grid-area: 1 / 1 / 2 / 2; }
.aboutuscontainerrow2 { grid-area: 1 / 2 / 2 / 3;
background-image: url('../assets/about-banner.png');
background-size: 100% 100%;
background-repeat: no-repeat;
padding: 50px;
}
.aboutustitle1{
  
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; /* Use the actual font name or fallback to a generic sans-serif font */
  color: #4624ae; /* Use the specific color value instead of --jet */
  font-size: 3rem; /* Use a specific font size instead of --fs-3 */
  font-weight: 500; /* Use a specific font weight instead of --fw-500 */
  
}
.aboutussubtitle1{
  text-align: start;
  color: gray;
}
.aboutussubtitle2{
  font-size: 0.9rem;
}

/* aboust us section end  */

/* popular flight route section start */
.populerflightroutecontainer{
  margin-left: 8vw;
  margin-right: 8vw;
  box-shadow: 0 0 3px 3px gray; 
  border: 1px solid gray;
  margin-top: 10vh;
  gap: 20px;
  margin-bottom: 10vh;
}
.populerflightroute{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  padding: 10px;
  padding-bottom: 20px;
}
.populerflightrouteheading{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: clamp(1.4rem, 4vw, 1.8rem);
  padding: 10px;
  background-color: white;
}

.populerflightroutebox{

  width: 320px; 
  height: 70px;
  margin: 10px;
background: transparent;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
grid-template-columns: 0.5fr 1fr;
grid-template-rows: 1fr;
}
.populerflightrouteboxcol1 { grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
  align-items: center;

}
.populerflightrouteboxcol2 { 
  grid-area: 1 / 2 / 2 / 3;
 padding: 10px;
 text-align: start;
}
.populerflightimage{
  height: 70px;
  width: 70%;
  border-radius: 50%;
}

/* responsive start  */

@media screen and (max-width:900px) {

.aboutuscontainer{
  grid-template-columns: 1fr;
}
.aboutuscontainerrow1,.aboutuscontainerrow2{
  grid-area: auto;
}
.aboutuscontainerrow2{
  height: 80vh;
}
}
@media (max-width:760px) {
  .flightcontainerfirstrow {
    width: 100%;
    min-height: 70vh;
    margin-bottom: -10vh;
    z-index: 0;
  }
  .image-slider{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(5, 3, 59, 0.3) 0px 18px 36px -18px inset;
    border-image: fill 0 linear-gradient(rgba(0, 0, 0, 0),#000000eb); 
   width: 100%;
    height: 38vh;
    background-size: 100% 100%;
    background-position: center;
    margin-top: -6vh;
  }
  .flightsearchcontainer{
    margin-left: 2vw;
    margin-right: 2vw;
    background-color: rgb(237, 238, 243);
    margin-top: -35vh;
    margin-bottom: 10vh;
  }
  .availablecontainer{
    padding: 5px;
  }
  .availablecontainerrow{
    display: flex;
    flex-wrap: wrap;
    /* background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9); */
    background-color: rgb(237, 238, 243);
    margin-bottom: -5vh;
  }
  .flight-flightcol{
  width: 165px;
    padding: 5px;
  }
  .flightcontainerthirdrowcol1 {
    width: 100%;
  height: 35vh;
  background-color: white;
  }
  .populerflightrouteheading{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 10px;
    background-color: white;
  }
  .populerflightroutecontainer{
    margin-left: 2vw;
    margin-right: 2vw;
    box-shadow: 0 0 3px 3px gray; 
    border: 1px solid gray;
    margin-top: 2vh;
    gap: 10px;
    margin-bottom: 2vh;
  }
  .populerflightroute{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: white;
    padding: 10px;
    padding-bottom: 20px;
  }
  
  .populerflightroutebox{
  
    width: 320px; 
    height: 70px;
    margin: 10px;
  background: transparent;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  }
  .populerflightrouteboxcol1 { grid-area: 1 / 1 / 2 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .populerflightrouteboxcol2 { 
    grid-area: 1 / 2 / 2 / 3;
   padding: 10px;
   text-align: start;
  }
  .populerflightimage{
    height: 70px;
    width: 70%;
    border-radius: 50%;
  }
 
}
