.flightsearchcontainernew {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 0.6fr 0.2fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 5px;
   padding-left: 2%;
   padding-right: 2%;
   margin-bottom: 50px;
background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9);
    z-index: 2;
    }
    .flightlistcontainer{
      background-color: transparent;
    }
    .dummy{
      background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9);
      height: 6vh;
      width: 100%;
    }
    .flightsearchcontainerrow1new { grid-area: 1 / 1 / 2 / 2;
        display: flex;
        justify-content: start;
  
        align-items: center;
        padding: 5px;
        margin-bottom: -20px;
       }
       .flightsearchcontainerrow2new { 
        margin-bottom: -4vh;
         background: transparent;
         grid-area: 2 / 1 / 3 / 2; }
       .flightsearchcontainerrow3new { grid-area: 3 / 1 / 4 / 2;
    
      }
       .flightrownew {
        display: flex;
        flex-wrap: wrap;
      background: transparent;
      }
      .dropdown-form2new{
        position: 'absolute';
                top: 50%;
                left: 68%;
                max-width: 280px;
                transform: 'translate(-50%, -50%)';
                width: '100%';
               background-color: white;
               border-radius: 5px;
                padding: '10px';
                z-index: 1000;
               box-shadow: 0 0 3px black;
               border: 1px solid blue;
               text-align: start;
               padding: 10px;
      }
      .f1body{
        display: flex;
        flex-direction: row;
        gap:10px;
  
      }  
     
  
      .f1bodybox {
        width: 30%; 
        background-color: rgb(184, 237, 255); 
        height: auto; 
        border: 1px solid rgb(0, 128, 126);
        box-shadow: 0 0 2px navy;
        border-radius: 5px;
        padding: 100px; 
        margin-bottom: 20px;
    }
    
    .f2bodybox {
        width: 70%; 
        background-color: white; 
        height: 100%; 
        border: 1px solid rgb(0, 128, 126);
        box-shadow: 0 0 2px navy;
        border-radius: 5px;
 
    }
    /* flightavailable css style */
  
      .f2bodyboxcontainer {
        display: grid;
        grid-template-columns: 1fr 0.3fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
   
        }
        
      
      .flightavailablebox1 {
         grid-area: 1 / 1 / 2 / 2; 
         display: flex;
         flex-wrap: wrap;
       background: transparent;
      }
       
      .flightavailablebox2 {
         grid-area: 1 / 2 / 2 / 3; 
         display: flex;
         flex-direction: column;
         flex-wrap: wrap;
         text-align: start;
         
        
        }
  
         
         .flightavailablebox1col {
      flex: 1;
      margin: 10px; 
      /* background-color: aqua; */
  
      
    }
    .flightavailableboxrow{
      flex:1;
      margin: 5px;
      
    }
   .hrline {
      border: none;
      height: 3px;
      background: transparent;
      background-image: linear-gradient(to right, #33ef5b 33%, transparent 0%);
      background-size: 14px 2px;
  }

  /*flight availability css*/
   .availablecontainer { 
    margin-bottom: -4vh;
     background: transparent;
      }
      .availablecontaineronly { 
  
         background: transparent;
          }
      .dummy2{
        background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9);
        height: 8vh;
        width: 100%;
      }
      .availablecontainerrow{
        display: flex;
        flex-wrap: wrap;
        /* background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9); */
        background-color: rgb(237, 238, 243);
      }
      .availablecontainerrowonly{
        display: flex;
        flex-wrap: wrap;
        background-image: linear-gradient(to right,#2475ef , #5ebef4 , #90fef9);
       
      }
      .loader {
        position: relative;
        width: 200px;
        height: 200px;
        background: transparent;
        border-radius: 50%;
        box-shadow: 25px 25px 75px rgba(0,0,0,0.55);
        border: 1px solid #333;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
      
      .loader::before {
        content: '';
        position: absolute;
        inset: 20px;
        background: transparent;
        border: 1px dashed#444;
        border-radius: 50%;
        box-shadow: inset -5px -5px 25px rgba(0,0,0,0.25),
        inset 5px 5px 35px rgba(0,0,0,0.25);
      }
      
      .loader::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px dashed#444;
        box-shadow: inset -5px -5px 25px rgba(0,0,0,0.25),
        inset 5px 5px 35px rgba(0,0,0,0.25);
      }
      
      .loader span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 100%;
        background: transparent;
        transform-origin: top left;
        animation: radar81 2s linear infinite;
        border-top: 1px dashed #fff;
      }
      
      .loader span::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: seagreen;
        transform-origin: top left;
        transform: rotate(-55deg);
        filter: blur(30px) drop-shadow(20px 20px 20px seagreen);
      }
      
      @keyframes radar81 {
        0% {
          transform: rotate(0deg);
        }
      
        100% {
          transform: rotate(360deg);
        }
      }
/* 
      dialog box style */
      .dialog-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
      }
      
      .dialog {
        background-color: white;
        border-radius: 5px;
        padding: 50px;
        width: auto;
        text-align: start;
        border: 2px solid rgb(86, 11, 184);
      }
    
      
     
      .dialog p {
        margin: 10px 0; /* spacing between paragraphs */
        font-size: 16px; /* adjust font size as needed */
        line-height: 1.5;
        padding: 3px; /* adjust line height as needed */
      }
        
      .dialog p:hover {
        margin: 10px 0; /* spacing between paragraphs */
        font-size: 16px; /* adjust font size as needed */
        line-height: 1.5;
        background-color: rgb(241, 243, 247); /* adjust line height as needed */
      }
      
      .dialog button {
        margin-top: 10px; /* spacing between buttons and paragraphs */
        padding: 10px 20px; /* button padding */
        font-size: 16px; /* button font size */
        background-color: #007bff; /* button background color */
        color: #fff; /* button text color */
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
      
      .dialog button:hover {
        background-color: #0056b3; /* button background color on hover */
      }
      .dialog table {
        width: 100%;
        border-collapse: collapse;
      }
      
      .dialog th,
      .dialog td {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        text-align: left;
      }
      
      .dialog th {
        background-color: #caeef8;
      }
      .dialog td {
        width: 10vw;
      }
      
      .dialog tr:hover {
        background-color: #f5f5f5;
      }
      
      .dialog th:first-child,
      .dialog td:first-child {
        border-left: none;
      }
      
      .dialog th:last-child,
      .dialog td:last-child {
        border-right: none;
      }
      