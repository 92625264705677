
/* 
.train-container {
    max-width: 800px;
    margin: auto;
    font-family: 'Arial', sans-serif;
  }
  
  .mb-3 {
    margin-bottom: 15px;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .form-control {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .mt-3 {
    margin-top: 15px;
    color: red;
  } */
  /* TrainSearchsecond.css */

.train-container {
width: 100%;
    margin: auto;
    font-family: 'Arial', sans-serif;
  }
  
  .mb-3 {
    margin-bottom: 15px;
  }
  
  .form-label {
    font-weight: bold;
    display: flex;
    justify-content: start;
    font-size: 0.9rem;
  }
  
  
  .form-control {
    width: 101%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .mt-3 {
    margin-top: 15px;
    color: red;
  }
  
  .mt-4 {
    margin-top: 20px;
  }
  
  /* Add these new styles for layout using Flexbox */
  
  .train-container {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px black;
    margin-right: 12%;
    margin-left: 12%;
  }
  
  .col {
    flex: 1;
    margin: 10px; /* Adjust as needed */
  }
  .swapcol{
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mt-4 {
    margin-top: 20px;
  }
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;

  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  #customers tr:hover {background-color: #ddd;}
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8e20d7;
    color: white;
  }  
  #fromStation {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  /* Style for the datalist */
  #stationSuggestions {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #c5c8f9;
    width: 100%;
    margin-top: 5px;
    overflow-y: auto;
  }
  /* Style for each option in the datalist */
  #stationSuggestions option {
    padding: 8px;
    cursor: pointer;
  }
  /* Highlight selected option in the datalist */
  #stationSuggestions option:checked {
    background-color: #007bff;
    color: #fff;
  }
  @media screen and (max-width:760px) {
    .form-label {
      font-weight: bold;
      display: flex;
      justify-content: start;
      font-size: 0.7rem;
    }
    
  .form-control {
    width: 101%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
    
  }